.about-us {
    padding: 20px;


}

.about-us h1,
.about-us h2 {
    color: #d44f86;
    text-align: center;
}

.about-us p,
.about-us ul {
    color: #ececec;
    text-align: center;
}

.about-us ul {
    list-style-type: disc;
    margin-left: 20px;
}