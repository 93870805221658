.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;

}

.logo img {
    height: 50px;
}

.nav-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #fff;
}

.nav-links a:hover {
    text-decoration: underline;
}