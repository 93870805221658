.contact-us {
    text-align: center;
    padding: 20px;
    height: 40vh;


}

.contact-us h2 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 70px;
}

.contact-us p {
    margin: 10px 0;
    color: #fff;
    font-size: 22px;
}


@media (max-width: 768px) {
    .contact-us h2 {
        font-size: 50px;
    }

    .contact-us p {

        font-size: 20px;
    }
}