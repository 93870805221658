.footer {
    background-color: #292c2f;
    color: white;
    padding: 50px;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-content p {
    margin: 10px 0;
    text-align: justify;

}


@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: flex-start;
    }

}